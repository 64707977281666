import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './DigitalConfirm.css';

import bt_playstone from '../images/p-d-n.png';
import bt_applesotre from '../images/ap-d-n.png';

interface ProductData {
  product_name: string;
  owner: string;
  edition: string;
  image_url: string;
}

const DigitalConfirmComponent: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [data, setData] = useState<ProductData | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://life-sync-api.vercel.app/read/digitalconfirm`, {
          params: { hashkey: slug }
        });
        const { product_name, owner, edition, image_url } = response.data;
        setData({ product_name, owner, edition, image_url });
        setError('');
      } catch (err) {
        setError('Error fetching data');
        setData(null);
      }
    };

    if (slug) {
      fetchData();
    }
  }, [slug]);

  return (
    <div className="container">
      <div className="top"></div>
      <div className="middle">
        <div className="box">
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {data ? (
            <div>
              <div className="image-containers">
                {data.image_url && <img src={data.image_url} alt="Product" />}
              </div>
              <div className="group1">
                <p><strong>Product Name:</strong></p>
                <p>{data.product_name}</p>
              </div>
              <div className="group1">
                <p><strong>Edition:</strong></p>
                <p>{data.edition}</p>
              </div>
              <div className="group1">
                <p><strong>Owner:</strong></p>
                <p>{data.owner}</p>
              </div>
            </div>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
      <div className="bottom">
        <p className='px'>Cool AR experiences await you!</p>
        <p>Download the app now!</p>
        <div className="store-links">
          <a href="https://play.google.com/store/search?q=artventure&c=apps&hl=th-TH" target="_blank" rel="noopener noreferrer">
            <img src={bt_playstone} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/th/app/artventurenft/id6472034909?l=th" target="_blank" rel="noopener noreferrer">
            <img src={bt_applesotre} alt="Apple Store" />
          </a>
        </div>
      </div>
      <div className="footer">
        <p>copy right 2024 DIGITEYE CO., LTD. Thailand</p>
      </div>
    </div>
  );
};

export default DigitalConfirmComponent;
