import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComponent from './pages/HomeComponent';
import DigitalConfirmComponent from './pages/DigitalConfirmComponent';
import DigitalConfirmRoyalSaluteComponent from './pages/DigitalConfirmRoyalSaluteComponent';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/:slug" element={<DigitalConfirmComponent />} />
        <Route path="/royal-salute/:slug" element={<DigitalConfirmRoyalSaluteComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
