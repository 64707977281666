import React, { useState } from 'react';
import './RoyalSalute.css';

import productImage1 from '../images/item0000001.png';
import productImage2 from '../images/item0000002.png';
import productImage3 from '../images/item0000003.png';
import leftArrow from '../images/arrowl.png'; // Your custom left arrow image
import rightArrow from '../images/arrowr.png'; // Your custom right arrow image
import bt_playstone from '../images/p-d-n.png';
import bt_applesotre from '../images/ap-d-n.png';

const productImages = [productImage1, productImage2, productImage3];

const DigitalConfirmRoyalSaluteComponent = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex + 1) % productImages.length);
  };

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length);
  };

  return (
    <div className="container">
      
      <div className='royal'>
      <h1>Royal Salute Platinum Jubilee Edition The Cullinan V Brooch</h1>
      <div className="image-container">
        <button className="image-nav left" onClick={handlePrevImage}>
          <img src={leftArrow} alt="Previous" />
        </button>
        <img src={productImages[imageIndex]} alt="Product" />
        <button className="image-nav right" onClick={handleNextImage}>
          <img src={rightArrow} alt="Next" />
        </button>
      </div>
      <div className="description">
        <h2>Description</h2>
        <p>This special-edition bottle of blended whisky from Royal Salute has been released to mark Queen Elizabeth II’s platinum jubilee. It’s one of seven distinct designs, each modeled after one of the Queen’s brooches. This is the Cullinan V version.</p>
      </div>
      <div className="details">
        <h2>Details</h2>
        <p><strong>Brand:</strong> Royal Salute</p>
        <p><strong>Edition:</strong> xxx</p>
        <p><strong>Country:</strong> Scotland</p>
        <p><strong>Size:</strong> 70cl</p>
        <p><strong>Variental:</strong> Blended Whisky</p>
        <p><strong>Owner:</strong> Artventure</p>
      </div>
      <div className="bottom"> 
        <p className='px'>Cool AR experiences await you!</p>
        <p>Download the app now!</p>
        <div className="store-links">
          <a href="https://play.google.com/store/search?q=artventure&c=apps&hl=th-TH" target="_blank" rel="noopener noreferrer">
            <img src={bt_playstone} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/th/app/artventurenft/id6472034909?l=th" target="_blank" rel="noopener noreferrer">
            <img src={bt_applesotre} alt="Apple Store" />
          </a>
        </div>
      </div>
      </div>
      <div className="footer">
        <p>copy right 2024 ArtventureNFT Thailand</p>
      </div>
    </div>
  );
};

export default DigitalConfirmRoyalSaluteComponent;
